import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      redirect: '/dashboard',
      name: 'root',
      component: () => import('@/view/layout/Layout'),
      children: [
        {
          path: '/dashboard',
          name: 'dashboard',
          component: () => import('@/view/pages/Dashboard.vue'),
        },
        {
          path: '/companies',
          name: 'companies',
          gates: 'admin',
          component: () => import('@/view/pages/companies/Companies'),
          beforeEnter: (to, from, next) => {
            console.log('TOUT')
            // if (!this.$gates.isSuperUser()) {
            //     next('/')
            // }
            next()
          },
          children: [
            {
              path: 'list',
              name: 'companies-list',
              component: () => import('@/view/pages/companies/List'),
            },
            {
              path: 'new',
              name: 'companies-new',
              component: () => import('@/view/pages/companies/New'),
            },
            {
              path: 'edit/:id',
              name: 'companies-edit',
              component: () => import('@/view/pages/companies/Edit'),
            },
            {
              path: 'daily-revenue',
              name: 'companies-daily-revenue',
              component: () => import('@/view/pages/companies/DailyRevenue'),
            },
          ],
        },
        {
          path: '/users',
          name: 'users',
          gates: 'admin',
          component: () => import('@/view/pages/users/Users'),
          children: [
            {
              path: 'list',
              name: 'users-list',
              component: () => import('@/view/pages/users/List'),
            },
            {
              path: 'new',
              name: 'users-new',
              component: () => import('@/view/pages/users/New'),
            },
            {
              path: 'edit/:id',
              name: 'users-edit',
              component: () => import('@/view/pages/users/Edit'),
            },
          ],
        },
        {
          path: '/loggers',
          name: 'loggers',
          component: () => import('@/view/pages/loggers/Loggers'),
          children: [
            {
              path: 'list',
              name: 'loggers-list',
              component: () => import('@/view/pages/loggers/List'),
            },
            {
              path: 'new',
              name: 'loggers-new',
              component: () => import('@/view/pages/loggers/New'),
            },
            {
              path: 'edit/:id',
              name: 'loggers-edit',
              component: () => import('@/view/pages/loggers/Edit'),
            },
          ],
        },
        {
          path: '/devices',
          name: 'devices',
          component: () => import('@/view/pages/devices/Devices'),
          children: [
            {
              path: 'list',
              name: 'devices-list',
              component: () => import('@/view/pages/devices/List'),
            },
            {
              path: 'new',
              name: 'devices-new',
              component: () => import('@/view/pages/devices/New'),
            },
            {
              path: 'edit/:id',
              name: 'devices-edit',
              component: () => import('@/view/pages/devices/Edit'),
            },
            {
              path: 'locations',
              name: 'devices-locations',
              component: () => import('@/view/pages/devices/Locations'),
            },
            {
              path: 'service-mode-history',
              name: 'devices-service-mode-history',
              component: () =>
                import('@/view/pages/devices/ServiceModeHistory'),
            },
            {
              path: 'maintenance/:id',
              name: 'maintenance',
              component: () => import('@/view/pages/devices/Maintenance'),
            },
            {
              path: 'critical-history',
              name: 'devices-critical-history',
              component: () => import('@/view/pages/devices/CriticalHistory'),
            },
            {
              path: 'free-ride',
              name: 'devices-free-ride',
              component: () => import('@/view/pages/devices/FreeRide'),
            },
            {
              path: 'reset',
              name: 'devices-reset',
              component: () => import('@/view/pages/devices/Reset'),
            },
          ],
        },
        {
          path: '/devices-maintenance',
          name: 'devices-maintenance',
          component: () =>
            import('@/view/pages/devices-maintenance/DeviceMaintenance'),
          children: [
            {
              path: 'device',
              name: 'devices-maintenance-device',
              component: () =>
                import('@/view/pages/devices-maintenance/ByDeviceMaintenance'),
            },
            {
              path: 'range',
              name: 'devices-maintenance-range',
              component: () =>
                import('@/view/pages/devices-maintenance/ByRangeMaintenance'),
            },
          ],
        },

        {
          path: '/cities',
          name: 'cities',
          gates: 'admin',
          component: () => import('@/view/pages/cities/Cities'),
          children: [
            {
              path: 'list',
              name: 'cities-list',
              component: () => import('@/view/pages/cities/List'),
            },
            {
              path: 'new',
              name: 'cities-new',
              component: () => import('@/view/pages/cities/New'),
            },
            {
              path: 'edit/:id',
              name: 'cities-edit',
              component: () => import('@/view/pages/cities/Edit'),
            },
          ],
        },
        {
          path: '/lessors',
          name: 'lessors',
          component: () => import('@/view/pages/lessors/Lessors'),
          children: [
            {
              path: 'list',
              name: 'lessors-list',
              component: () => import('@/view/pages/lessors/List'),
            },
            {
              path: 'new',
              name: 'lessors-new',
              component: () => import('@/view/pages/lessors/New'),
            },
            {
              path: 'edit/:id',
              name: 'lessors-edit',
              component: () => import('@/view/pages/lessors/Edit'),
            },
          ],
        },
        {
          path: '/locations',
          name: 'locations',
          component: () => import('@/view/pages/locations/Locations'),
          children: [
            {
              path: 'list',
              name: 'locations-list',
              component: () => import('@/view/pages/locations/List'),
            },
            {
              path: 'new',
              name: 'locations-new',
              component: () => import('@/view/pages/locations/New'),
            },
            {
              path: 'edit/:id',
              name: 'locations-edit',
              component: () => import('@/view/pages/locations/Edit'),
            },
          ],
        },
        {
          path: '/tags',
          name: 'tags',
          component: () => import('@/view/pages/tags/Tags'),
          children: [
            {
              path: 'list',
              name: 'tags-list',
              component: () => import('@/view/pages/tags/List'),
            },
            {
              path: 'new',
              name: 'tags-new',
              component: () => import('@/view/pages/tags/New'),
            },
            {
              path: 'edit/:id',
              name: 'tags-edit',
              component: () => import('@/view/pages/tags/Edit'),
            },
          ],
        },
        {
          path: '/deposits',
          name: 'deposits',
          component: () => import('@/view/pages/deposits/Deposit'),
          children: [
            /*   {
                               path: 'list',
                               name: 'deposits-list',
                               component: () => import('@/view/pages/deposits/List'),
                           },*/
            {
              path: 'list',
              name: 'deposits-list',
              component: () => import('@/view/pages/deposits/ListNew'),
            },
            /*{
                            path: 'new',
                            name: 'deposits-new',
                            component: () => import('@/view/pages/deposits/New'),
                        },
                        {
                            path: 'delete',
                            name: 'deposits-delete',
                            component: () => import('@/view/pages/deposits/Delete'),
                        },*/
          ],
        },
        {
          path: '/detailed-review',
          name: 'detailed-review',
          component: () =>
            import('@/view/pages/detailed-review/DetailedReview'),
          children: [
            {
              path: 'list',
              name: 'detailed-review-list',
              component: () => import('@/view/pages/detailed-review/List.vue'),
            },
          ],
        },
        {
          path: '/today-revenue',
          name: 'today-revenue',
          component: () => import('@/view/pages/today-revenue/TodayRevenue'),
          children: [
            {
              path: 'list',
              name: 'today-revenue-list',
              component: () => import('@/view/pages/today-revenue/List.vue'),
            },
          ],
        },
        {
          path: '/finance-report',
          name: 'finance-report',
          component: () => import('@/view/pages/finance-report/FinanceReport'),
          children: [
            {
              path: 'device',
              name: 'finance-report-device',
              component: () => import('@/view/pages/finance-report/Device'),
            },
            {
              path: 'device-new',
              name: 'finance-report-device-new',
              component: () => import('@/view/pages/finance-report/DeviceNew'),
            },
            {
              path: 'lessor',
              name: 'finance-report-lessor',
              component: () => import('@/view/pages/finance-report/Lessor'),
            },
            {
              path: 'lessor-new',
              name: 'finance-report-lessor-new',
              component: () => import('@/view/pages/finance-report/LessorNew'),
            },
            {
              path: 'location',
              name: 'finance-report-location',
              component: () => import('@/view/pages/finance-report/Location'),
            },
            {
              path: 'location-new',
              name: 'finance-report-location-new',
              component: () =>
                import('@/view/pages/finance-report/LocationNew'),
            },
            {
              path: 'company',
              name: 'finance-report-company',
              component: () => import('@/view/pages/finance-report/Company'),
            },
            {
              path: 'export',
              name: 'finance-report-export',
              component: () => import('@/view/pages/finance-report/Export'),
            },
          ],
        },
        {
          path: '/comparative-analysis',
          name: 'comparative-analysis',
          component: () =>
            import('@/view/pages/comparative-analysis/ComparativeAnalysis'),
          children: [
            {
              path: 'device',
              name: 'comparative-analysis-device',
              component: () =>
                import('@/view/pages/comparative-analysis/Device'),
            },
            {
              path: 'location',
              name: 'comparative-analysis-location',
              component: () =>
                import('@/view/pages/comparative-analysis/Location'),
            },
            {
              path: 'lessor',
              name: 'comparative-analysis-lessor',
              component: () =>
                import('@/view/pages/comparative-analysis/Lessor'),
            },
          ],
        },
        {
          path: '/ebitda',
          name: 'ebitda',
          component: () => import('@/view/pages/ebitda/Ebitda'),
          children: [
            {
              path: 'list',
              name: 'ebitda-list',
              component: () => import('@/view/pages/ebitda/List'),
            },
          ],
        },
        {
          path: '/articles',
          name: 'articles',
          component: () => import('@/view/pages/articles/Articles'),
          children: [
            {
              path: 'items',
              name: 'articles-items',
              component: () => import('@/view/pages/articles/items/Items'),
              children: [
                {
                  path: 'list',
                  name: 'articles-items-list',
                  component: () => import('@/view/pages/articles/items/List'),
                },
                {
                  path: 'new',
                  name: 'articles-items-new',
                  component: () => import('@/view/pages/articles/items/New'),
                },
                {
                  path: 'edit/:id',
                  name: 'articles-items-edit',
                  component: () => import('@/view/pages/articles/items/Edit'),
                },
              ],
            },
            {
              path: 'repositories',
              name: 'articles-repositories',
              component: () =>
                import('@/view/pages/articles/repositories/Repositories'),
              children: [
                {
                  path: 'list',
                  name: 'articles-repositories-list',
                  component: () =>
                    import('@/view/pages/articles/repositories/List'),
                },
                {
                  path: 'new',
                  name: 'articles-repositories-new',
                  component: () =>
                    import('@/view/pages/articles/repositories/New'),
                },
                {
                  path: 'edit/:id',
                  name: 'articles-repositories-edit',
                  component: () =>
                    import('@/view/pages/articles/repositories/Edit'),
                },
              ],
            },
          ],
        },
        {
          path: '/loyalty',
          name: 'loyalty',
          component: () => import('@/view/pages/loyalty/Loyalty'),
          children: [
            {
              path: 'owners',
              name: 'loyalty-owners',
              component: () => import('@/view/pages/loyalty/owners/Owners'),
              children: [
                {
                  path: 'list',
                  name: 'loyalty-owners-list',
                  component: () => import('@/view/pages/loyalty/owners/List'),
                },
                {
                  path: 'new',
                  name: 'loyalty-owners-new',
                  component: () => import('@/view/pages/loyalty/owners/New'),
                },
                {
                  path: 'edit/:id',
                  name: 'loyalty-owners-edit',
                  component: () => import('@/view/pages/loyalty/owners/Edit'),
                },
                {
                  path: 'transactions',
                  name: 'loyalty-owners-transactions',
                  component: () =>
                    import('@/view/pages/loyalty/owners/Transactions'),
                },
              ],
            },
            {
              path: 'cards',
              name: 'loyalty-cards',
              component: () => import('@/view/pages/loyalty/cards/Cards'),
              children: [
                {
                  path: 'list',
                  name: 'loyalty-cards-list',
                  component: () => import('@/view/pages/loyalty/cards/List'),
                },
                {
                  path: 'upload',
                  name: 'loyalty-cards-upload',
                  component: () => import('@/view/pages/loyalty/cards/Upload'),
                },
                {
                  path: 'new',
                  name: 'loyalty-cards-new',
                  component: () => import('@/view/pages/loyalty/cards/New'),
                },
                {
                  path: 'edit/:id',
                  name: 'loyalty-cards-edit',
                  component: () => import('@/view/pages/loyalty/cards/Edit'),
                },
                {
                  path: 'transactions',
                  name: 'loyalty-cards-transactions',
                  component: () =>
                    import('@/view/pages/loyalty/cards/Transactions'),
                },
              ],
            },
            {
              path: 'users',
              name: 'loyalty-users',
              component: () => import('@/view/pages/loyalty/users/Users'),
              children: [
                {
                  path: '/loyalty/promo-boxes',
                  name: '/promo-boxes',
                  component: () =>
                    import('@/view/pages/loyalty/promo-boxes/PromoBoxes'),
                  children: [
                    {
                      path: 'list',
                      name: 'loyalty-promo-boxes-list',
                      component: () =>
                        import('@/view/pages/loyalty/promo-boxes/List'),
                    },
                    {
                      path: 'new',
                      name: 'loyalty-promo-boxes-new',
                      component: () =>
                        import('@/view/pages/loyalty/promo-boxes/New'),
                    },
                    {
                      path: 'edit/:id',
                      name: 'loyalty-promo-boxes-edit',
                      component: () =>
                        import('@/view/pages/loyalty/promo-boxes/Edit'),
                    },
                  ],
                },
                {
                  path: 'list',
                  name: 'loyalty-users-list',
                  component: () => import('@/view/pages/loyalty/users/List'),
                },
                {
                  path: 'edit/:id',
                  name: 'loyalty-users-edit',
                  component: () => import('@/view/pages/loyalty/users/Edit'),
                },
                {
                  path: 'transactions',
                  name: 'loyalty-users-transactions',
                  component: () =>
                    import('@/view/pages/loyalty/users/Transactions'),
                },
              ],
            },
            {
              path: 'top-up',
              name: 'loyalty-top-up',
              component: () => import('@/view/pages/loyalty/top-up/TopUp'),
              children: [
                {
                  path: 'list',
                  name: 'loyalty-top-up-list',
                  component: () => import('@/view/pages/loyalty/top-up/List'),
                },
                {
                  path: 'new',
                  name: 'loyalty-top-up-new',
                  component: () => import('@/view/pages/loyalty/top-up/New'),
                },
              ],
            },
            {
              path: 'general-report',
              name: 'loyalty-general-report',
              component: () =>
                import('@/view/pages/loyalty/general-report/GeneralReport.vue'),
            },
            {
              path: 'reports',
              name: 'loyalty-reports',
              component: () => import('@/view/pages/loyalty/reports/Reports'),
              children: [
                {
                  path: 'owner',
                  name: 'loyalty-reports-owner',
                  component: () => import('@/view/pages/loyalty/reports/Owner'),
                },
                {
                  path: 'card',
                  name: 'loyalty-reports-card',
                  component: () => import('@/view/pages/loyalty/reports/Card'),
                },
                {
                  path: 'user',
                  name: 'loyalty-reports-user',
                  component: () => import('@/view/pages/loyalty/reports/User'),
                },
              ],
            },
            {
              path: 'promotions',
              name: 'loyalty-promotions',
              component: () =>
                import('@/view/pages/loyalty/promotions/Promotions'),
              children: [
                {
                  path: 'all',
                  name: 'loyalty-promotions-all',
                  component: () =>
                    import('@/view/pages/loyalty/promotions/All'),
                },
              ],
            },
            {
              path: 'pos-owners',
              name: 'loyalty-pos-owners',
              component: () =>
                import('@/view/pages/loyalty/pos-owners/PosOwners.vue'),
              children: [
                {
                  path: 'list',
                  name: 'loyalty-pos-owners-list',
                  component: () =>
                    import('@/view/pages/loyalty/pos-owners/List'),
                },
                {
                  path: 'new',
                  name: 'loyalty-pos-owners-edit',
                  component: () =>
                    import('@/view/pages/loyalty/pos-owners/New.vue'),
                },
                {
                  path: 'edit/:id',
                  name: 'loyalty-pos-owners-edit',
                  component: () =>
                    import('@/view/pages/loyalty/pos-owners/Edit'),
                },
              ],
            },
            {
              path: 'pos-locations',
              name: 'loyalty-pos-locations',
              component: () =>
                import('@/view/pages/loyalty/pos-locations/PosLocations.vue'),
              children: [
                {
                  path: 'list',
                  name: 'loyalty-pos-locations-list',
                  component: () =>
                    import('@/view/pages/loyalty/pos-locations/List'),
                },
                {
                  path: 'new',
                  name: 'loyalty-pos-locations-edit',
                  component: () =>
                    import('@/view/pages/loyalty/pos-locations/New.vue'),
                },
                {
                  path: 'edit/:id',
                  name: 'loyalty-pos-locations-edit',
                  component: () =>
                    import('@/view/pages/loyalty/pos-locations/Edit'),
                },
              ],
            },
            {
              path: 'pos-workers',
              name: 'loyalty-pos-workers',
              component: () =>
                import('@/view/pages/loyalty/pos-workers/PosWorkers.vue'),
              children: [
                {
                  path: 'list',
                  name: 'loyalty-pos-workers-list',
                  component: () =>
                    import('@/view/pages/loyalty/pos-workers/List'),
                },
                {
                  path: 'new',
                  name: 'loyalty-pos-workers-edit',
                  component: () =>
                    import('@/view/pages/loyalty/pos-workers/New.vue'),
                },
                {
                  path: 'edit/:id',
                  name: 'loyalty-pos-workers-edit',
                  component: () =>
                    import('@/view/pages/loyalty/pos-workers/Edit'),
                },
              ],
            },
          ],
        },
        {
          path: '/transactions',
          name: 'transactions',
          component: () => import('@/view/pages/transactions/Transactions'),
          children: [
            {
              path: 'list',
              name: 'transactions-list',
              component: () => import('@/view/pages/transactions/List.vue'),
            },
          ],
        },
        {
          path: '/bills',
          name: 'bills',
          component: () => import('@/view/pages/bills/Bills'),
          children: [
            {
              path: 'list',
              name: 'bills-list',
              component: () => import('@/view/pages/bills/List.vue'),
            },
            {
              path: 'filter',
              name: 'bills-filter',
              component: () => import('@/view/pages/bills/Filter.vue'),
            },
            {
              path: 'new',
              name: 'bills-new',
              component: () => import('@/view/pages/bills/New.vue'),
            },
            {
              path: 'request',
              name: 'bills-request',
              component: () => import('@/view/pages/bills/Request'),
            },
            {
              path: 'unfiscalized',
              name: 'bills-unfiscalized',
              component: () => import('@/view/pages/bills/Unfiscalized'),
            },
          ],
        },
        {
          path: '/transactions-bills',
          name: 'transactions-bills',
          component: () =>
            import('@/view/pages/transactions-bills/TransactionsBills'),
          children: [
            {
              path: 'comparison',
              name: 'transactions-bills-comparison',
              component: () =>
                import('@/view/pages/transactions-bills/Comparison.vue'),
            },
          ],
        },
        {
          path: '/exports',
          name: 'exports',
          component: () => import('@/view/pages/bills/Bills'),
          children: [
            {
              path: 'generate',
              name: 'exports-generate',
              component: () => import('@/view/pages/exports/Generate.vue'),
            },
          ],
        },
        {
          path: '/imports',
          name: 'imports',
          component: () => import('@/view/pages/imports/Imports.vue'),
          children: [
            {
              path: 'history',
              name: 'imports-history',
              component: () => import('@/view/pages/imports/History.vue'),
            },
          ],
        },
        {
          path: '/settings',
          name: 'settings',
          component: () => import('@/view/pages/settings/Settings'),
          children: [
            {
              path: 'main',
              name: 'settings-main',
              component: () => import('@/view/pages/settings/Main'),
            },
          ],
        },
        {
          path: '/profile',
          name: 'profile',
          component: () => import('@/view/pages/profile/Profile'),
          children: [
            {
              path: 'overview',
              name: 'profile-overview',
              component: () => import('@/view/pages/profile/Profile-1.vue'),
            },
          ],
        },
      ],
    },
    {
      path: '/',
      component: () => import('@/view/pages/auth/login/Login'),
      children: [
        {
          name: 'login',
          path: '/login',
          component: () => import('@/view/pages/auth/login/Login'),
        },
      ],
    },
    {
      path: '*',
      redirect: '/404',
    },
    {
      // the 404 route, when none of the above matches
      path: '/404',
      name: '404',
      component: () => import('@/view/pages/error/Error-1.vue'),
    },
  ],
})
